@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}
html {
  scroll-padding-top: 80px; /* height of sticky header */
}

body {
  padding-top: 80px;
}
